import React from "react";
import styled from "styled-components";

const Col = styled.div`
  margin: 0 0 10px 0;
`;

const TableSortButton = styled.button`
  cursor: pointer;

  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const DiscourseTableSort = ({ activeTableSort, setActiveTableSort }) => (
  <>
    <Col className="nhg-small heading">
      <TableSortButton
        onClick={() =>
          setActiveTableSort(
            activeTableSort === "discoursetitle"
              ? "-discoursetitle"
              : "discoursetitle"
          )
        }
      >
        Title
      </TableSortButton>
    </Col>

    <Col className="nhg-small heading">
      <TableSortButton
        onClick={() =>
          setActiveTableSort(
            activeTableSort === "discoursecategory"
              ? "-discoursecategory"
              : "discoursecategory"
          )
        }
      >
        Category
      </TableSortButton>
    </Col>

    <Col className="nhg-small heading">
      <TableSortButton
        onClick={() =>
          setActiveTableSort(
            activeTableSort === "discourseyear"
              ? "-discourseyear"
              : "discourseyear"
          )
        }
      >
        Year
      </TableSortButton>
    </Col>
  </>
);
