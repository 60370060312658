/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = property => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "title") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  }

  if (property === "client") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.client.text.localeCompare(a.node.data.client.text);
      } else {
        return a.node.data.client.text.localeCompare(b.node.data.client.text);
      }
    };
  }

  if (property === "tags") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.tags[0].localeCompare(a.node.tags[0]);
      } else {
        return a.node.tags[0].localeCompare(b.node.tags[0]);
      }
    };
  }

  if (property === "year") {
    return function(a, b) {
      if (sortOrder === -1) {
        // return b.node.data.year.localeCompare(a.node.data.year);
      } else {
        // return a.node.data.year.localeCompare(b.node.data.year);
      }
    };
  }

  if (property === "discourseyear") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.year.localeCompare(a.node.data.year);
      } else {
        return a.node.data.year.localeCompare(b.node.data.year);
      }
    };
  }

  if (property === "discoursetitle") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  }

  if (property === "discoursesource") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.source.text.localeCompare(a.node.data.source.text);
      } else {
        return a.node.data.source.text.localeCompare(b.node.data.source.text);
      }
    };
  }

  if (property === "discoursecategory") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.tags[0].localeCompare(a.node.tags[0]);
      } else {
        return a.node.tags[0].localeCompare(b.node.tags[0]);
      }
    };
  }
};
