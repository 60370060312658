import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../components/context/window-width";
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { DiscourseDesktop } from "../components/discourse/discourse-desktop";
import { DiscourseMobile } from "../components/discourse/discourse-mobile";

const Page = styled.div`
  & .heading {
    margin: 0 0 10px 0;
  }

  & .footnotes,
  & .external-links {
    margin: 12px 0;
  }

  & .footnotes {
    & h3 {
      margin: 0 0 20px 0;
    }

    & .footnote-number {
      margin: 0;
    }

    & .footnote-text {
      & p:first-of-type {
        margin-top: 0;
      }
    }
  }

  & hr {
    border: 0;
    border-bottom: 1px solid #555;
    margin: 0;
  }
`;

const DiscourseContent = ({ data, setPageColor, setPageType }) => {
  const windowWidth = useContext(WindowWidthContext);

  useEffect(() => {
    setPageColor(`White`);
    setPageType("practice");
  }, []);

  const preloadImages = data.allPrismicDiscourse.edges.map((content, index) => {
    if (content.node.data.image.fluid !== null) {
      return (
        <link
          rel="preload"
          as="image"
          href={content.node.data.image.fluid.srcWebp}
          imagesrcset={content.node.data.image.fluid.srcSetWebp}
          key={`preloaded_discourse_images_${index}`}
        />
      );
    }
  });

  return (
    <>
      <Helmet>{preloadImages}</Helmet>

      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => <DiscourseMobile data={data} />}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => <DiscourseDesktop data={data} />}
      />
    </>
  );
};

const Discourse = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PageColorConsumer>
        {({ setPageColor }) => (
          <Page>
            <Helmet title="Discourse – PUP ARCHITECTS" />
            <DiscourseContent
              setPageColor={setPageColor}
              setPageType={setPageType}
              data={data}
            />
          </Page>
        )}
      </PageColorConsumer>
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    allPrismicDiscourse(sort: { fields: data___year, order: DESC }) {
      edges {
        node {
          uid
          tags
          data {
            image {
              fluid(maxHeight: 1500) {
                srcSetWebp
                srcWebp
              }
              alt
            }
            year(formatString: "YYYY")
            title {
              html
              text
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Discourse);
