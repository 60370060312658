import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Articles = styled.div`
  border-top: 1px solid #555;
`;

const Row = styled.div`
  border-bottom: 1px solid #555;

  & a {
    display: block;
    width: 100%;
    margin: 16px 0 11px 0;
  }

  cursor: pointer;

  h1,
  h2,
  a {
    font-family: "neue-haas-grotesk-text", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  & p {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  & .active-content {
    & img {
      margin: 13px 0 0 0;
    }
  }
`;

const Grid = styled.div`
  display: block;
`;

const GridCol = styled.div`
  width: 100%;
  padding: 20px 0;

  & .text-container {
    max-width: 550px;
    margin: 0 auto;
  }
`;

const ContentContainer = styled.div`
  text-align: center;

  padding: 6px 30px 4px 30px;

  & p {
    margin: 0;
  }
`;

export const DiscourseMobile = ({ data }) => {
  const [activeRow, setActiveRow] = useState(null);
  const [activeContent, setActiveContent] = useState(null);
  const [activeContentIndex, setActiveContentIndex] = useState(null);
  const [activeTableSort, setActiveTableSort] = useState(`-year`);

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);

  const discourse = data.allPrismicDiscourse.edges.map((content, index) => {
    return (
      <Row
        key={`projects_${index}_${content.node.id}`}
        onClick={() => {
          activeContentIndex === index
            ? setActiveContentIndex(null)
            : setActiveContentIndex(index);
        }}
        className={
          activeContentIndex === index || activeRow === index ? `is-active` : ``
        }
      >
        <ContentContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: content.node.data.title.html,
            }}
          />

          <p>{content.node.tags[0]}</p>
          <p>{content.node.data.year}</p>

          {activeContentIndex === index && (
            <div className="active-content">
              <img
                src={content.node.data.image.fluid.srcWebp}
                srcSet={content.node.data.image.fluid.srcSetWebp}
                alt={content.node.data.image.alt}
                loading="lazy"
              />
              <Link to={`/discourse/${content.node.uid}`}>Read Article</Link>
            </div>
          )}
        </ContentContainer>
      </Row>
    );
  });

  return (
    <Grid>
      <GridCol>
        <Articles activeRowIndex={activeRow}>{discourse}</Articles>
      </GridCol>
    </Grid>
  );
};
