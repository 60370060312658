import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Functions
import { dynamicSort } from "../utils/dynamic-sort";

// Components
import { DiscourseTableSort } from "../discourse/discourse-table-sort";

const HeaderRow = styled.div`
  // padding: 10px 0;

  border-bottom: 1px solid #555;

  cursor: pointer;

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
`;

const Articles = styled.div`
  &:hover {
    & > div {
      border-color: #e5e5e5;
    }
  }

  & > div:nth-of-type(${props => props.activeRowIndex}) {
    border-color: #000;
  }

  & > div:nth-of-type(${props => props.activeRowIndex + 1}) {
    color: #000;
    border-color: #000;

    & h1,
    & h2,
    & p,
    & a {
      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;

      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
`;

const Row = styled.div`
  border-bottom: 1px solid #555;

  & a {
    display: block;
    width: 100%;
    padding: 8px 0 4px 0;
  }

  cursor: pointer;

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  &.is-active {
    & a {
      padding: 6px 0 5px 0;
    }
  }
`;

const Col = styled.div`
  & p {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};
  grid-gap: ${props => (props.desktopGridGap ? props.desktopGridGap : `0px`)};
`;

const GridCol = styled.div`
  width: 100%;
  padding: 20px;

  &.scrollable {
    overflow: scroll;
    height: 100vh;
  }

  & .text-container {
    max-width: 550px;
    margin: 0 auto;
  }

  & .active-content {
    padding: 20px 0;
  }

  &:first-of-type {
    border-right: 1px solid #555;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: calc(100vh - 20px - 40px);
  padding: 20px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }
`;

export const DiscourseDesktop = ({ data }) => {
  const [activeImage, setActiveImage] = useState(
    data.allPrismicDiscourse.edges[0].node.data.image
  );
  const [activeRow, setActiveRow] = useState(0);
  const [activeContent, setActiveContent] = useState(null);
  const [activeContentIndex, setActiveContentIndex] = useState(null);
  const [activeTableSort, setActiveTableSort] = useState(`-year`);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const discourse = data.allPrismicDiscourse.edges
    .sort(dynamicSort(activeTableSort))
    .map((content, index) => (
      <Row
        key={`projects_${index}_${content.node.id}`}
        onMouseOver={() => {
          setActiveImage(content.node.data.image);
          setActiveRow(index);
        }}
        onClick={() => {
          setActiveContent(content.node.data);
          setActiveContentIndex(index);
        }}
        className={
          activeContentIndex === index || activeRow === index ? `is-active` : ``
        }
      >
        <Link to={`/discourse/${content.node.uid}`}>
          <Grid desktopCol={`1fr 90px 60px`} desktopGridGap={`20px`}>
            <Col>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.node.data.title.html,
                }}
              />
            </Col>
            <Col>
              <div>
                <p>{content.node.tags[0]}</p>
              </div>
            </Col>
            <Col>
              <div>
                <p>{content.node.data.year}</p>
              </div>
            </Col>
          </Grid>
        </Link>
      </Row>
    ));

  return (
    <Grid desktopCol={`1fr 1fr`}>
      <GridCol>
        {activeImage !== null && activeContent === null && (
          <ProjectImage>
            {activeImage.fluid !== null && (
              <img
                src={activeImage.fluid.srcWebp}
                srcSet={activeImage.fluid.srcSetWebp}
                alt={activeImage.alt}
                loading="lazy"
              />
            )}
          </ProjectImage>
        )}
      </GridCol>

      <GridCol className="scrollable">
        <HeaderRow>
          <Grid desktopCol={`1fr 90px 60px`} desktopGridGap={`20px`}>
            <DiscourseTableSort
              activeTableSort={activeTableSort}
              setActiveTableSort={setActiveTableSort}
            />
          </Grid>
        </HeaderRow>

        <Articles activeRowIndex={activeRow}>{discourse}</Articles>
      </GridCol>
    </Grid>
  );
};
